.main-layout {
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}