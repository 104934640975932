/* General styles */
body {
  font-family: 'Helvetica Neue', Arial, sans-serif;
  color: #333;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
}

h2 {
  color: #e60028;
  font-size: 1.5em;
  margin-bottom: 1em;
}

/* Form row */
.form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.form-row input,
.form-row button {
  flex: 1;
  min-width: 150px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.form-row input:focus {
  border-color: #e60028;
  outline: none;
}

.form-row button {
  background-color: #e60028;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.form-row button:hover {
  background-color: #cc0022;
}

/* Suggestions list */
.hidden {
  display: none;
}


.form-group {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: bold;
}

.form-group input,
.search input,
.search select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.form-group input:focus {
  border-color: #e60028;
  outline: none;
}

.search button {
  background-color: #e60028;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  font-size: 1em;
  margin-left: 2px;
}

.suggestions-list {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.9);
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
  max-height: 150px;
  overflow-y: auto;
  width: 300%;
  /* Adjust based on input padding and border */
  z-index: 1000;
  top: calc(100% - 0px);
  padding-left: 10px;

}

.suggestions-list li {
  padding-bottom: 10px;
  padding-left: 2px;
  cursor: pointer;
  list-style: none;
}

.suggestions-list li:hover {
  background-color: #eee;
}

/* Item table */
.item-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.item-table th,
.item-table td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.item-table th {
  background-color: #f1f1f1;
}

.item-table tr.even {
  background-color: #f9f9f9;
}

.item-table tr.odd {
  background-color: #fff;
}

.item-table tr:hover {
  background-color: #eee;
}

.item-table button {
  background-color: #e60028;
  color: #fff;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  margin-right: 5px;
}

.item-table button:hover {
  background-color: #cc0022;
}

/* Pagination */
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  padding-bottom: 20px;
}

.pagination button {
  background-color: #e60028;
  color: #fff;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
}

.pagination button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
  background-color: #cc0022;
}

/* Popup */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup {
  background: white;
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.popup h2 {
  margin-top: 0;
}

.popup button {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #e60028;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup button:hover {
  background-color: #cc0022;
}