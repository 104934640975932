.footer {
  background-color: rgb(51, 51, 51);
  ;
  padding: 20px 0;
  border-top: 1px solid #e6e6e6;
}

.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.footer-links {
  display: flex;
  gap: 20px;
  margin-bottom: 10px;
  color: white;
}

.footer-links a {
  color: #333;
  text-decoration: none;
  transition: color 0.3s;
  color: white;
}

.footer-links a:hover {
  color: #e60028;
}

.footer-copyright {
  color: #666;
  font-size: 0.9em;
}