.header {
    background-color: #fff;
    border-bottom: 1px solid #e6e6e6;
    padding: 20px 0;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .logo {
    height: 50px;
  }
  
  h1 {
    color: #e60028;
    font-size: 1.8em;
    margin: 0;
  }
  
  .logout-button {
    background-color: #e60028;
    color: #fff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 5px;
  }
  
  .logout-button:hover {
    background-color: #cc0022;
  }
  