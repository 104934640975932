.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.login-form {
  background-color: #fff;
  border: 1px solid #e6e6e6;
  padding: 40px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.login-form h2 {
  color: #e60028;
  font-size: 1.8em;
  margin-bottom: 20px;
}

.login-form input {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 1em;
}

.login-form input:focus {
  border-color: #e60028;
  outline: none;
}

.login-form button {
  background-color: #e60028;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  border-radius: 5px;
  font-size: 1em;
  margin: 2px;
}

.login-form button:hover {
  background-color: #cc0022;
}

.google-signin-button {
  margin: 10px 0;
  padding: 10px;
  width: 250px;
  background-color: #4285f4;
  color: white;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
}